import "./App.css";
import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";
import ProjectHome from "./pages/Projects";
import Layout from "./pages/Layout";
import NoPage from "./pages/NoPage";
import ReactDOM from "react-dom";
import Amio from "./pages/Amio";
import Flatform from "./pages/Flatform";
import TeamBranding from "./pages/TeamBranding";
import Onboarding from "./pages/Onboarding";
import Study from "./pages/Study";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ProjectHome />} />
          <Route path="/amio" element={<Amio />} />
          <Route path="/flatform" element={<Flatform />} />
          <Route path="/team-branding" element={<TeamBranding />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/study" element={<Study />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

export default App;
