import { Helmet } from "react-helmet";

const Branding = () => {
  return (
    <>
      <Helmet>
        <title>Jasmine Worsley | Branding</title>
      </Helmet>
      <div className="text">
        <div className="topCorner">
          <a href="#billions">Team Billions</a>
          <a href="#supernova">Team Supernova</a>
        </div>
        <section>
          <h1>
            <span>Team Branding</span>
          </h1>
          <div className="callout">
            <div className="content">
              <h2>Overview—</h2>
              <p>
                I designed branding for two internal Atlassian teams while I
                have been there. Both intended to bring the team together and
                create an identity.
              </p>
            </div>
            <div className="content-short">
              <h2>Role</h2>
              <p>Designer & Illustrator</p>
            </div>
            <div className="content-short">
              <h2>Time commitment</h2>
              <p>1 Month</p>
            </div>
          </div>
          <h2 id="billions">Team Billions</h2>
          <p>
            I was approached by the team when I returned to Atlassian to create
            a team logo. The team had already had a branding workshop to
            determine a name, and came up with the name 'Billions'. The name is
            a combination of "Billing", and "Lions", which conveniently combine
            to create 'Billions' - the returns we hope to provide the company.
          </p>
          <p>
            I worked with the teams senior designer to do some ideation. We each
            found some inspiration and then met to discuss what we liked and
            didn't like, and our visions. I started my individual exploration
            the same way I usually do for branding - with a moodboard. Some of
            the inspiration was specific to the 'lion' idea, and some was my
            vision for a old, stamp-like design.
          </p>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Billions Moodboard"
              src={require("../assets/branding/billions-moodboard.png")}
            />
          </figure>
          <p>
            We re-grouped to discuss our inspiration, and decide on a direction.
            Working with someone else was very valuable, as one of his ideas
            actually ended up being a jumping off point that I hadn't
            considered.
          </p>
          <h3>Ideation</h3>
          <p>
            Myself and the other designer both got back to the drawing board,
            creating some more fleshed out designs. My sketches lead me to three
            concepts:{" "}
          </p>
          <div className="prototype">
            <img
              alt="Billions bandana design"
              className="protoImage"
              style={{ outline: "1px solid #dab9ee" }}
              src={require("../assets/branding/billions-bandana.png")}
            />
            <div className="protoText">
              <h4>The bandana</h4>
              This design riffs off the bandana design from my moodboard. To me,
              this style really feels lux. It has elements of the lion, and of
              dollar bills.
            </div>
          </div>
          <div className="prototype">
            <img
              alt="Billions seal design"
              className="protoImage"
              style={{ outline: "1px solid #dab9ee" }}
              src={require("../assets/branding/billions-seal.png")}
            />
            <div className="protoText">
              <h4>The seal</h4>
              Several of my ideas drew from old seals and stickers. This design
              was based on that, with an illustration. Illustration is not my
              strong suit, and I figured I could refine the design if it was the
              direction we chose to go in.
            </div>
          </div>
          <div className="prototype">
            <img
              alt="Billions money mane design"
              className="protoImage"
              style={{ outline: "1px solid #dab9ee" }}
              src={require("../assets/branding/billions-mane.png")}
            />
            <div className="protoText">
              <h4>Money mane</h4>
              This design was inspired by a design from the other designer on
              this project. He had designed a cartoonish Lion with dollar bills
              as a mane. I loved this concept, and created my own twist.
            </div>
          </div>

          <h3>Critique</h3>
          <p>
            We discussed all the designs together, deciding that the money mane
            design was the most representative of the fun, quirky, but serious
            nature of the team. There were a few suggestions:
            <ul>
              <li>It feels more like an illustration than a logo</li>
              <li>It feels too serious</li>
            </ul>
          </p>
          <h3>Final design</h3>
          <p>
            The final design was a combination of style we explored, using the
            concept of the lion with the mane made of dollar bills. This style
            felt like it better represented the team, and better lent itself to
            other merchandise options such as stickers (both on slack and
            physical), alongside being more recognisable at a smaller scale.
          </p>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Billions final design"
              src={require("../assets/branding/billions-final.png")}
            />
          </figure>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Billions tshirt mockup"
              src={require("../assets/branding/billions-shirt.png")}
            />
          </figure>
          <h3>In Retrospect</h3>
          <p>
            I'm thrilled with how this turned out, and the buy in we managed to
            create. It was difficult getting the team onboard with the same
            design to begin with - a project with many stakeholders, all
            invested heavily in the outcome.
          </p>
          <p>
            We had some great buy in on the logo - the team loved the shirts,
            and when the office has been open we see them a lot! The slack emoji
            also get a lot of use. I think we were able to leverage this
            branding to really bring the team together and form an identity.
          </p>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Billions slack buy in"
              src={require("../assets/branding/slack.png")}
            />
          </figure>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Billions zoom buy in"
              src={require("../assets/branding/zoom.png")}
            />
          </figure>

          <h2 id="supernova">Team Supernova</h2>
          <p>
            Supernova was the first team branding I worked on at Atlassian. We
            held a branding workshop, where we determined the name and the
            identity of the team. The brief was simple: logo and t-shirt for
            Supernova. It should capture the theme of the team.
          </p>
          <p>
            I started by doing some research, especially since I wasn't entirely
            sure what a Supernova looked like! I also pulled some inspiration
            from other space-related designs.
          </p>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Supernova moodboard"
              src={require("../assets/branding/supernova-moodboard.png")}
            />
          </figure>
          <h3>Ideation</h3>
          <p>
            I did a fair bit of sketching, and eventually was able to come to a
            few fleshed out designs.
          </p>

          <div className="prototype">
            <img
              alt="Supernova circle design"
              className="protoImage"
              style={{ outline: "1px solid #dab9ee" }}
              src={require("../assets/branding/supernova-circle.png")}
            />
            <div className="protoText">
              <h4>The circle</h4>
              This design integrates a few commerce specific items such as
              hidden constellations forming yen and dollar signs. This design
              feels very in line with other commerce designs, in a more
              corporate style.
            </div>
          </div>
          <div className="prototype">
            <img
              alt="Supernova 80s style design"
              className="protoImage"
              style={{ outline: "1px solid #dab9ee" }}
              src={require("../assets/branding/supernova-2.png")}
            />
            <div className="protoText">
              <h4>80s Inspo</h4>
              This design was a bit less serious and a bit more young/friendly.
              It incorporates the supernova as its key element, but also has
              prominent typography. This definitely makes it more recognisable,
              but also makes the shirt less conducive to everyday wear.
            </div>
          </div>
          <div className="prototype">
            <img
              alt="Supernova gradient design"
              className="protoImage"
              style={{ outline: "1px solid #dab9ee" }}
              src={require("../assets/branding/supernova-mod.png")}
            />
            <div className="protoText">
              <h4>Gradient</h4>
              Though I like this design, it was the first to be axed. When
              considering from the start that a t-shirt was involved, this
              design had to be ruled out as it is impossible to screenprint
              accurate gradients, and we had decided to screenprint the shirts.
            </div>
          </div>

          <h3>Critique</h3>
          <p>
            I had a crit session with the team, as they were the key
            stakeholders. It was of top importance that there was team buy in,
            because the logo was meant to be illustrating the identity of the
            team. I had already narrowed it down to the first two ideas, and of
            these, many critiques and suggestions were raised including:
            <ul>
              <li>The team name isn't prominent enough</li>
              <li>We're bored of the red color, can we try something else?</li>
              <li>
                The circle is cool, but it reminds us too much of the JPY shirt
                that we all already have
              </li>
            </ul>
          </p>

          <h3>Design refinement</h3>
          <p>
            With these suggestions I got back to the drawing board. I pulled
            elements from the first design, which was widely regarded as the
            team's favorite.
          </p>

          <figure className="space-down-l space-up-xs">
            <div className="row">
              <div className="column">
                <img
                  alt="Supernova purple black colorway"
                  className="border"
                  src={require("../assets/branding/supernova-final-3.png")}
                />
              </div>
              <div className="column">
                <img
                  alt="Supernova blue transparent colorway"
                  className="border"
                  src={require("../assets/branding/supernova-final-4.png")}
                />
              </div>
              <div className="column">
                <img
                  alt="Supernova orange black colorway"
                  className="border"
                  src={require("../assets/branding/supernova-final-5.png")}
                />
              </div>
              <div className="column">
                <img
                  alt="Supernova yellow black colorway"
                  className="border"
                  src={require("../assets/branding/supernova-final-6.png")}
                />
              </div>
              <div className="column">
                <img
                  alt="Supernova blue pink colorway"
                  className="border"
                  src={require("../assets/branding/supernova-final-7.png")}
                />
              </div>
            </div>
          </figure>
          <p>
            I explored a number of different colorways for the final design, and
            proposed these to the team during one of our meetings. We decided
            that orange was not too far from our original design, but a little
            more punchy.
          </p>
          <h3>Final design</h3>
          <figure className="space-down-l space-up-xs">
            <div className="row">
              <div className="column">
                <img
                  alt="Supernova Final design"
                  className="border"
                  src={require("../assets/branding/supernova-final.png")}
                />
              </div>
              <div className="column">
                <img
                  alt="Supernova tshirt mockup"
                  className="border"
                  src={require("../assets/branding/supernova-shirt.png")}
                />
              </div>
            </div>
          </figure>
          <h3>In Retrospect</h3>
          <p>
            I loved working on this branding and I felt it brought the team
            closer. It was a great exercise in taking critique - thought I have
            experience taking UX critique, I have significantly less in
            illustration. I also found this to be a good exercise in
            communication, leading these critiques with a huge diversity of
            language and barriers that made comprehending critique and
            suggestions difficult at times.
          </p>
          <p>
            I was very happy with the outcome of this project, and the team also
            was thrilled. I left Atlassian with t-shirt contracts in the works
            and a logo left with the team. Unfortunately this was right at the
            beginning of COVID, and stay and home orders thwarted their
            printing. Eventually, the team disbanded, meaning the shirts were
            never printed.
          </p>
        </section>
      </div>
    </>
  );
};

export default Branding;
