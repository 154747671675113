import { Helmet } from "react-helmet";

const Amio = () => {
  return (
    <>
      <Helmet>
        <title>Jasmine Worsley | Amio</title>
      </Helmet>
      <div className="text">
        <div className="topCorner">
          <a href="#initial_research">Research</a>
          <a href="#the_problem">Problem</a>
          <a href="#ideating">Ideation</a>
          <a href="#design_system">Design System</a>
          <a href="#the_solution">Solution</a>
          <a href="#retrospect">Retrospect</a>
        </div>
        <section>
          <h1>
            <span>Amio's app and design system</span>
          </h1>
          <div className="callout">
            <div className="content">
              <h2>Overview—</h2>
              <p>
                Amio was an early stage startup I cofounded with three others,
                working on creative vision and design. It was a platform aimed
                at allowing travellers to plan their holidays like a pro,
                anywhere, anytime, with confidence.
              </p>
            </div>
            <div className="content-short">
              <h2>Role</h2>
              <p>Co-founder, Lead Designer</p>
            </div>
            <div className="content-short">
              <h2>Time commitment</h2>
              <p>1.5 Years</p>
            </div>
          </div>
          <h2 id="initial_research">The idea</h2>
          <p>
            My cofounder approached me early in 2020 with an ambitious proposal:
            AI that recommends people travel experiences personalised to their
            needs. As time went on, the way in which we strived to accomplish
            this would change, but the vision remained the same. The team
            consisted of a triad specialising in design (myself), engineering,
            and product.
          </p>
          <h2 id="initial_research">Initial research</h2>
          <p>
            While we were yet to have a well formed problem, we were able to
            determine our potential user base with relative ease. We planned to
            target younger, tech fluent, spontaneous travellers that are bad at
            planning, and tend to cite their major issues as changing plans and
            changing environments.
          </p>
          <p>
            With the idea in mind, we set out to do some thorough research for
            roughly 6 months. As this project began right in the height of
            COVID-19, we faced some unusual challenges with our research. To
            combat the lack of readily available international and domestic
            travellers in NZ, we avoided making assumptions by:
            <ul>
              <li>
                To combat the lack of readily available international travellers
                in NZ, we conducted early, comprehensive questionnaires to
                gather broad insight from a large range of grounded overseas
                travellers. This didn't require our presence as an evaluator,
                and provided both quantitative and qualitative data. Our
                questions were a mix of Likert items, ranking, open ended
                comments, and closed questions.
              </li>
              <li>
                We got into contact with many local government bodies
                (Wellington, Queenstown, Christchurch to name a few), and
                Tourism New Zealand and were able to access their tourism data
                and conduct research with the existing data from pre-COVID, and
                projected data from post-COVID.
              </li>
              <li>
                When domestic tourism restarted for a few months, we visited
                local hotspots like Queenstown and did in person interviews. We
                would have conducted roughly 50 interviews over a few days, all
                with some central questions but also following leads where
                necessary to gather complete data.
              </li>
              <li>
                We talked to a number of small and large tourism businesses
                about how their customers find them, and their issues.
              </li>
            </ul>
          </p>
          <p>
            We had a lot of interesting findings across the board with these
            methods of research, with consistent results. Our interviews noted
            the following:
          </p>
          <div className="guidelines">
            <div className="callout guideline">
              60% of interviewees said it was difficult to find suitable new
              experiences.
            </div>
            <div className="callout guideline">
              57% of interviewees said the time taken to find experiences was
              unacceptably long.
            </div>
            <div className="callout guideline">
              56% of interviewees said more than half of their tourism
              experiences were new to them.
            </div>
          </div>
          <h2>Building Empathy</h2>
          <p>
            We narrowed our user base to two core groups we felt we could
            support simultaneously. These were:
            <ul>
              <li>
                Young professionals and families that are tech-fluent, don't
                enjoy planning and/or have little experience planning, and have
                a desire to travel spontaneously
              </li>
              <li>
                Small 10 or less person businesses that are particularly niche
                or indigenous, with little/no online presence, and use marketing
                which primarily relies on word of mouth.
              </li>
            </ul>
            From this I crafted a draft of a user journey map for the traveller
            we decided to support. This helped me to understand when and where
            our product might be able to help.
          </p>
          <figure>
            <a
              href={require("../assets/amio/journeymap.png")}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Journey map"
                src={require("../assets/amio/journeymap.png")}
              />
            </a>
          </figure>
          <p>
            {" "}
            I then formed 3 persona for our traveller, and 3 persona for our
            business owners. Here are a few of them:
          </p>
          <figure className="space-down-l space-up-xs">
            <div className="row">
              <div className="column">
                <a
                  href={require("../assets/amio/amiopersona1.jpg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Lyne user persona"
                    className="border"
                    src={require("../assets/amio/amiopersona1.jpg")}
                  />
                </a>
              </div>
              <div className="column">
                <a
                  href={require("../assets/amio/amiopersona2.jpg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Aroha user persona"
                    className="border"
                    src={require("../assets/amio/amiopersona2.jpg")}
                  />
                </a>
              </div>
              <div className="column">
                <a
                  href={require("../assets/amio/amiopersona3.jpg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Jake user persona"
                    className="border"
                    src={require("../assets/amio/amiopersona3.jpg")}
                  />
                </a>
              </div>
            </div>
          </figure>
          <h2 id="the_problem">The Problem</h2>
          <p>
            From our research, and based on our personas, we derived a few key
            issues for each of our target groups.
          </p>
          <div className="callout">
            <h2>The Problem for travellers</h2>
            <ul>
              <li>
                Travellers don't know what to do on holiday and waste time
                searching through irrelevant experiences
              </li>
              <li>
                Customers lack access to enriching Maori tourism experiences,
                likely due to a lack of knowledge of indigenous culture.
              </li>
              <li>
                Travellers whos plans change, for example, due to weather,
                struggle to find new activities on demand.
              </li>
            </ul>
          </div>
          <div className="callout">
            <h2>The Problem for small tourism suppliers</h2>
            <ul>
              <li>
                Small suppliers spend less time on their core offering as they
                are spending more time on SEO and markeing, often not having
                this experience nor the budget to employ someone who does.
              </li>
              <li>
                Marketplaces tend to take a high commission for passing a sale,
                between 15 and 50 percent, which small suppliers cannot afford -
                thus pricing them out of the marketplace.
              </li>
            </ul>
          </div>

          <h2 id="ideating">Ideation</h2>
          <p>
            Ideation started with a big team brainstorm of our problems, and how
            we thought we might solve them. The drawings are not too clear, and
            mainly acted as an illustration while we spoke. We decided that
            early on we should focus on a flexible web interface that could be
            used on mobile, because it was more likely that travellers would
            compromise on a website than less tech-fluent business owners would
            compromise on an app.
          </p>
          <figure className="space-down-l space-up-xs">
            <div className="row">
              <div className="column">
                <a
                  href={require("../assets/amio/whiteboard2.jpg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Whiteboard 1"
                    className="border"
                    src={require("../assets/amio/whiteboard2.jpg")}
                  />
                </a>
              </div>
              <div className="column">
                <a
                  href={require("../assets/amio/whiteboard1.jpg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Whiteboard 2"
                    className="border"
                    src={require("../assets/amio/whiteboard1.jpg")}
                  />
                </a>
              </div>
              <div className="column">
                <a
                  href={require("../assets/amio/whiteboard3.jpg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Whiteboard 3"
                    className="border"
                    src={require("../assets/amio/whiteboard3.jpg")}
                  />
                </a>
              </div>
            </div>
          </figure>
          <p>
            Our whiteboard design meetings eventually became a very complex Miro
            board, housing our vision, inspiration, journey maps, persona
            canvases
          </p>
          <figure className="space-down-l space-up-xs">
            <img alt="Miro board" src={require("../assets/amio/miro.png")} />
          </figure>
          <p>
            From my takeaways discussing with the product owner and lead
            engineer, I worked on some early wireframes that incorporated some
            of the ideas the team discussed. Having these wireframes allowed me
            to better explain to them potential solutions, and evaluate them
            together. With the discussion we had, I did a digital wireframe of
            the first iteration we came to.
          </p>
          <figure className="space-down-l space-up-xs">
            <div className="row">
              <div className="column4">
                <a
                  href={require("../assets/amio/ideation1.png")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Wireframe 1"
                    className="border"
                    src={require("../assets/amio/ideation1.png")}
                  />
                </a>
              </div>
              <div className="column4">
                <a
                  href={require("../assets/amio/ideation2.png")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Wireframe 2"
                    className="border"
                    src={require("../assets/amio/ideation2.png")}
                  />
                </a>
              </div>
              <div className="column4">
                <a
                  href={require("../assets/amio/ideation3.png")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Wireframe 3"
                    className="border"
                    src={require("../assets/amio/ideation3.png")}
                  />
                </a>
              </div>
              <div className="column4">
                <a
                  href={require("../assets/amio/ideation4.png")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Wireframe 4"
                    className="border"
                    src={require("../assets/amio/ideation4.png")}
                  />
                </a>
              </div>
            </div>
          </figure>
          <h2 id="design_system">Product Identity & Design System</h2>
          <p>
            Before I could explore a solution, we needed an identity and system
            for the product that could be followed. Considering I now had a
            basic wireframe, I was able to create some guidelines and start to
            build this design system. I knew a fair number of components that
            would be needed, but this design system really started to come
            together later when the prototype was being made.
          </p>
          <p>First I created some design guidelines that I wanted to follow:</p>
          <div className="guidelines">
            <div className="callout guideline">
              <h2>Simplicity</h2>
              The concept is very complex, and the simpler we can make it, the
              happer people will be.
            </div>
            <div className="callout guideline">
              <h2>Transparency</h2>
              We wanted users to be fully aware of the data we are collecting by
              asking straight up.
            </div>
            <div className="callout guideline">
              <h2>Fun & young</h2>
              We want the product to feel exciting, in order to attract the
              right audience and compete well in the market.
            </div>
          </div>
          <h3>The visual design</h3>
          <p>
            The visual design of this system was based on the Koru map pin
            logomark. I wanted it to be fun and friendly, to draw in the
            audience we were targeting. We followed the branding guidelines,
            using the predefined colors and fonts to achieve consistency between
            our social media, pitch decks, and other resources.
          </p>
          <h3>The design system</h3>
          <p>
            I wanted to create a set of harmonious building blocks that could be
            reused throughout the solution, ensuring consistency and familiarity
            on every screen. The design system needed to be very
            straightforward, as the team building it were all working part time
            on this effort. The design system and its documentation was housed
            directly in Figma, allowing the documentation to be easily updated
            through the inevitable changes and past the MVP stage. This allowed
            quick design generation, as all the components were individually
            designed already, and provided a comprehensive reference for future
            designers. Below is an example of one of the components that were
            part of our design system.
          </p>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Design System example"
              src={require("../assets/amio/designsystemexample.png")}
            />
          </figure>

          <h2 id="the_solution">The Solution</h2>
          <p>
            With some core ideas in mind, I built a prototype that we could use
            to show to a test group. I started with the main dashboard/home
            screen, and then worked outwards from this. This prototype was just
            for the MVP, so it is relatively low featured.
          </p>
          <h4>The Onboarding</h4>
          <p>
            The hardest and most important part of the design was the
            onboarding. This was the thing that we all spent the most time on,
            determining data gathering format that was going to be simple and
            straightforward, not bore a customer so much they wouldn't finish
            it, but would also gather the correct info to build the data model
            that allowed us to create recommendations. Initially, the onboarding
            had a set of complex questions with custom multi-choice answers.
          </p>
          <div className="callout">
            <h2>UX testing the onboarding</h2>
            We ran a Wizard of Oz style UX evaluation session with a few
            representative users prior to us having a working machine learning
            model, or working product. We used the prototype with the user,
            observing how they answered the onboarding questions, and then our
            product owner would decide on some recommendations and send them
            back to the user. This helped us to determine whether the UX was
            straightforward, and the key takeaway was that users had an
            information overload and eventually became disinterested. We ended
            up changing a large portion of our question set to be images to
            avoid this. We re-tested this with the same users, and had a much
            more positive response.
          </div>
          <p>
            We settled on a combined approach which gathered basic info (age,
            fitness level, etc.), and then gathering preferences via images
            rather than by explicit questions. The data model had very specific
            category names that were not user friendly, and the best solution we
            could come to was to show the user related images to illustrate
            these complex categories better. I worked very collaboratively on
            this process, involving engineers and product at every point. I
            wanted to ensure everything created truly represented the vision and
            skill of the team.
          </p>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Onboarding gif"
              src={require("../assets/amio/onboarding.gif")}
            />
          </figure>
          <h4>The App</h4>
          <p>
            I worked on the prototype for several months, iterating and
            gathering continuous feedback with the team at weekly sparring
            sessions. Eventually we got to a point that we felt was simple
            enough to provide customer value, but also be technologically
            feasible for the team.
          </p>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Onboarding gif"
              src={require("../assets/amio/mapproto.gif")}
            />
          </figure>

          <h2 id="retrospect">In Retrospect</h2>
          <p>
            I left the company shortly after this project ended, and it is still
            currently pre-launch. The designs went on to be further UX tested,
            evaluated by some design experts via our accelerator, and altered
            slightly to its current design. I'm really happy with the design
            system that I created as it left the startup in good hands to carry
            the branding and principles that I crafted with the other
            co-founders. Although we are unable to see the impact yet of the
            solution, I do believe that we made and tested a solution that will
            have the impact we expect.
          </p>
          <p>
            If I could change anything, it would have been providing components
            as they were complete (but prior to the MVP design being complete)
            so that the team could develop the components individually. I noted
            after this project, that producing the design system and MVP
            together was not as important as I first thought. Providing the
            design system to engineering first would have allowed them to work
            better in parallel with me, and give me more time to design at a
            higher quality.
          </p>
        </section>
      </div>
    </>
  );
};

export default Amio;
