import { Helmet } from "react-helmet";

const Study = () => {
  return (
    <>
      <Helmet>
        <title>Jasmine Worsley | Study App</title>
      </Helmet>
      <div className="text">
        <div className="topCorner">
          <a href="#idea">Idea</a>
          <a href="#exploration">Exploration</a>
          <a href="#ideation">Ideation</a>
          <a href="#prototyping">Prototype</a>
          <a href="#solution">The Solution</a>
          <a href="#retrospect">Restrospection</a>
        </div>
        <section>
          <h1>
            <span>Study App</span>
          </h1>
          <div className="callout">
            <div className="content">
              <h2>Overview—</h2>
              <p>
                This was a university project for an app design and development
                course. The concept was simple, an app that collates all the
                information you need every day at uni in the simplest possible
                form.
              </p>
            </div>
            <div className="content-short">
              <h2>Role</h2>
              <p>Designer</p>
            </div>
            <div className="content-short">
              <h2>Time commitment</h2>
              <p>1 week</p>
            </div>
          </div>
          <h2 id="idea">The idea</h2>
          <p>
            The brief for this project was very simple: design and develop a
            simple app that would improve some function of your day. The
            timeframe was very tight, with both design and development needing
            to be complete in a week, meaning the project scope needed to be
            very condensed. Being a full time student at the time, I wanted to
            do something relevant to that. I also love planning and
            organisation, and figured that my current paper planning system
            could be digitised.
          </p>
          <h2 id="exploration">Exploration</h2>
          <p>
            The brief for this project was very simple: design and develop a
            simple app that would improve some function of your day. Being a
            full time student at the time, I wanted to do something relevant to
            that. I also love planning and organisation, and figured that my
            current paper planning system could be digitised.
          </p>
          <p>
            Since I was designing this app for myself, I worked based off a user
            profile rather than a persona. And that profile was myself! Of
            course, I wanted it to be extensible to other students too, so
            alongside my profile I developed one other representative persona
            alongside myself that I felt represented the other type of student
            that might use this app.
          </p>
          <figure className="space-down-l space-up-xs">
            <div className="row">
              <div className="column">
                <img
                  alt="Jasmine User Profile"
                  className="border"
                  src={require("../assets/study/study-profiles4.jpg")}
                />
              </div>
              <div className="column">
                <img
                  alt="Jonothan User Persona"
                  className="border"
                  src={require("../assets/study/study-profiles3.jpg")}
                />
              </div>
            </div>
          </figure>
          <p>
            With this in mind, I began with a few brainstorms of what I thought
            the users would want. The first brainstorm was for my profile, the
            things that I want out of this app. I came to a few major groups of
            tasks I wished to accomplish, and sorted them based on priority and
            how they would work with other tasks. I only had a week for the this
            project, and had never built an app before so was under time
            pressure. I prioritised these ideas so that I knew what I needed to
            design and develop in what order.
          </p>
          <p>
            The second brainstorm was for my persona, Jonothan. He had some
            overlapping needs and some further needs. This helped me to then
            create a list of tasks that both our users would require.
          </p>

          <figure className="space-down-l space-up-xs">
            <div className="row">
              <div className="column">
                <img
                  alt="Brainstorm for Jasmine"
                  className="border"
                  src={require("../assets/study/brainstorm.jpg")}
                />
              </div>
              <div className="column">
                <img
                  alt="Brainstorm for Jonothan"
                  className="border"
                  src={require("../assets/study/brainstorm-2.jpg")}
                />
              </div>
            </div>
          </figure>
          <p>
            Since there was time pressure, I prioritised and determined the
            three top tasks I wanted users to be able to accomplish. I hope to
            provide more extensibility to cover more of these problems in
            future, but for now I had to seriously cut the scope to ensure the
            project could be complete in the week timeframe. These three tasks
            were:
            <ul>
              <li>Adding and removing courses</li>
              <li>
                Adding, completing, and adding descriptions for assignments
              </li>
              <li>Tagging assignments with difficulty and grade</li>
            </ul>
          </p>
          <h2 id="ideation">Ideation</h2>
          <p>
            With the tasks determined, I started some loose ideation. Having
            spent a lot of years thinking about the optimal ways to plan, I
            could very strongly visualise a solution in my head. I got this down
            through some sketches.
          </p>
          <p>
            I find that drawing an architecture diagram usually helps me to
            understand where to start with the design. Luckily having a really
            solid vision helped to quite easily flesh out the architecture.{" "}
            <br />
            <br />
            Following this, I did some rapid ideation to figure out how to
            structure the experience.
          </p>
          <figure className="space-down-l space-up-xs">
            <div className="row">
              <div className="column">
                <img
                  alt="App architecture"
                  className="border"
                  src={require("../assets/study/app-architecture.jpg")}
                />
              </div>
              <div className="column">
                <img
                  alt="Initial design ideation"
                  className="border"
                  src={require("../assets/study/app-rapid2.jpg")}
                />
              </div>
              <div className="column">
                <img
                  alt="Initial design ideation"
                  className="border"
                  src={require("../assets/study/app-rapid1.jpg")}
                />
              </div>
            </div>
          </figure>

          <h2 id="prototyping">Prototyping</h2>
          <div className="callout">
            <div className="content">
              <p>
                This project lacks a lot of external critique, due to its
                timeframe. I would have loved to spend more time refining the
                designs and gathering perspectives from users, however
                unfortunately the week timespan for both design and development
                left me roughly two days to work on the experience. If I wanted
                to pick this project back up I would definitely want to do more
                validation.
              </p>
            </div>
          </div>
          <p>
            With these rough sketches I spent some time polishing the designs in
            a prototype. In tandem, I created a very basic design system for
            extensibility, as I have many further ideas for this project and
            want to be able to build on it easily.
          </p>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Design system"
              src={require("../assets/study/designsystem.png")}
            />
          </figure>
          <p>
            Building this small design system helped me to start to think about
            the components as their own pieces of UX, with their own
            experiences. It let me work on the experience that every piece of
            the UI would give to the user in more detail, without worrying about
            the overall journey.
          </p>
          <p>
            Having this system in place let me focus on the user journey, as
            seen here:
          </p>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Initial prototype and flows"
              src={require("../assets/study/app-architecture.png")}
            />
          </figure>

          <div className="prototype">
            <img
              alt="Prototype gif"
              className="protoImage"
              src={require("../assets/study/study-proto.gif")}
            />
            <div className="protoText">
              <h2 id="solution">The solution</h2>
              With the prototype done, I made some minor adjustments and tested
              it with a few students for their perspectives. The major issues
              people had were with items I had to cut for time reasons, which
              made me very confident that I had covered the bases that I set out
              to.
              <br />
              <br />
              The user's journey starts with their onboarding flow - the are
              introduced to the app (name is a placeholder!) and given the
              opportunity to add their courses. Following this, they are
              directed to the dashboard, which is where their interactions with
              the app will start after their first use. The dashboard is very
              simple, hosting two tabs for 'courses' and 'assignments'. Courses
              is where the course information lives, and in future where you
              would view your timetable. Unfortunately due to time this was not
              possible in an MVP state.
              <br />
              <br />
              The assignments tab shows at a glance the most important
              information, and allows for GPA tracking and other information in
              the more detailed assignment tab. The assignments are all notated
              with a color representing the course they are attached to, but
              also display at a glance the course title.
            </div>
          </div>
          <h2 id="retrospect">In retrospect</h2>
          <p>
            Looking back on this assignment I am very happy with the final
            outcome. I solved three of the issues I set out to, and left the
            door open with a solid design system to extend the app's
            functionality in future.
          </p>
          <p>
            I also found that the designs translated very well into a real
            product, and the design system made this infinitely easier. I
            definitely learned a lot about how to design for development,
            ensuring that my task as a developer would not be too difficult, but
            also not compromising on the design.
          </p>
        </section>
      </div>
    </>
  );
};

export default Study;
