import { Helmet } from "react-helmet";

function Project(props) {
  let name = `case-study ${props.alignment}`;
  const listItems = props.tags.map((number) => (
    <span id="tag" style={{ backgroundColor: props.tagColor }}>
      {number}
    </span>
  ));
  return (
    <>
      <Helmet>
        <title>Jasmine Worsley</title>
      </Helmet>
      <div className={name}>
        <a
          href={props.url}
          draggable="false"
          title={props.title}
          className={props.color}
        >
          <div className="thumb">
            <span className="img">
              <img draggable="false" src={props.image} alt={props.title} />
            </span>
          </div>
          <div className="description">
            <p className="title">{props.title}</p>
            <p>{props.description}</p>
            <p className="tags">{listItems}</p>
          </div>
        </a>
        <br />
      </div>
    </>
  );
}

export function Projects() {
  return (
    <div className="projectList">
      <section>
        <h2>About Me —</h2>
        <h1>
          Hi! I'm Jasmine. I am a designer and front end engineer, who loves
          working with passionate teams on design problems and with customers to
          solve their needs.
        </h1>
        <h2>Projects —</h2>
        <div className="projects">
          <Project
            url="/amio"
            color="purple"
            tagColor="#DFC4FF"
            alignment="large text-right"
            title="Your perfect travel agent from your phone"
            description="I created a place for people to connect to the local Mum & Pop travel experiences from their fingertips"
            image={require("./assets/amiocover.png")}
            tags={["Design System", "Web"]}
          />
          <Project
            url="/flatform"
            color="yellow"
            tagColor="#fafe8d"
            alignment="large text-left"
            title="Organise your housemates without the arguments"
            description="An app that helps share houses to deal with the mundane tasks, so they can enjoy the time they have together."
            image={require("./assets/flatformcover.png")}
            tags={["UX Research", "App"]}
          />

          <h2>Specific skills —</h2>
          <Project
            url="/team-branding"
            color="blue"
            tagColor="#c2ecf2"
            alignment="small text-right"
            title="Show your team spirit"
            description="Creating team identities for two Atlassian internal teams, along with merchandise."
            image={require("./assets/tshirts.png")}
            tags={["Identity", "Illustration"]}
          />
          <Project
            url="/onboarding"
            color="pink"
            tagColor="#FFADCF"
            alignment="small text-left"
            title="Gamifying your onboarding tours"
            description="A virtual experience alongside a traditional tour."
            image={require("./assets/charliego.png")}
            tags={["App", "Hackathon"]}
          />
          <Project
            url="/study"
            color="purple"
            tagColor="#DFC4FF"
            alignment="small text-right"
            title="Helping you study smarter"
            description="A one stop shop for all your university needs."
            image={require("./assets/studycover.png")}
            tags={["App", "UI design"]}
          />
        </div>
      </section>
    </div>
  );
}
