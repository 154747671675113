import { Helmet } from "react-helmet";

const Onboarding = () => {
  return (
    <>
      <Helmet>
        <title>Jasmine Worsley | CharlieGo</title>
      </Helmet>
      <div className="text">
        <div className="topCorner">
          <a href={"#problem"}>The Problem</a>
          <a href="#research">Research</a>
          <a href="#building-empathy">Building Empathy</a>
          <a href="#ideation">Ideation</a>
          <a href="#prototyping">Prototyping</a>
          <a href="#solution">The Solution</a>
          <a href="#retrospect">Retrospect</a>
        </div>
        <section>
          <h1>
            <span>CharlieGo gamified onboarding tour</span>
          </h1>
          <div className="callout">
            <div className="content">
              <h2>Overview—</h2>
              <p>
                During a hackathon, we designed and shipped a gamified version
                of Atlassian's onboarding tour, that aimed to engage new
                employees while they got to know their new workspaces. Time was
                really a crunch here, everything we did was very fast.
              </p>
            </div>
            <div className="content-short">
              <h2>Role</h2>
              <p>Designer</p>
            </div>
            <div className="content-short">
              <h2>Time commitment</h2>
              <p>48hrs</p>
            </div>
          </div>
          <h2 id="problem">The Problem</h2>
          <p>
            As Atlassian expands, it is important to develop engaging processes
            that promote understanding of the culture and the environment
            Atlassians work in. More specifically, it poses a growing challenge
            for large groups starting at once, such as interns and graduates.
            This is not just an issue for facilitators of new hires (e.g.
            recruiters), but also for new hires themselves as they want to both
            understand the spaces they will be working in, but will be battling
            with what is likely a long day of orientation tasks.{" "}
          </p>
          <p>
            Currently, tours are guided by volunteers in medium-large groups
            (around 10-20 people). I remember this posing an issue myself on
            this tour as I was not only tired from a full on day, but also very
            excited to meet people which distracted from the tour. The groups
            often crossed over in places, and I’m sure that I accidentally
            changed groups a fair number of times throughout the tour. This
            ensured I knew where very little was, which became a problem as soon
            as I started working. Talking to other interns, it seems everyone
            faced similar problems. Others also mentioned although they found it
            useful, the tour only showed a few floors of one building, which was
            unhelpful for those working in a different place.
          </p>
          <h2>The idea</h2>
          <p>
            We propose a supplemental gamified tour experience that creates a
            competitive scavenger hunt style environment and promotes better
            navigation for new hires, and creates relationships between team
            mates.
          </p>
          <h2 id="research">Research</h2>
          <h3>What is gamification?</h3>
          <p>
            Gamification is the “use of game design elements in non-game
            contexts”{" "}
            <a
              href="https://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.186.3039&rep=rep1&type=pdf"
              target="_blank"
              rel="noreferrer"
            >
              (Deterding, 2011)
            </a>{" "}
            which enhances engagement with the experience and motivates a
            specific user behaviour. Gamification lends itself to a number of
            things we wanted to improve:
          </p>
          <div className="guidelines">
            <div className="callout guideline">
              <h2>Better learning experience</h2>
              The learner experiences “fun”, and still learn if the level of
              engagement is high. A good gamification strategy with high levels
              of engagement will lead to an increase in recall and retention.
            </div>
            <div className="callout guideline">
              <h2>Better learning environment.</h2>
              The learner experiences “fun”, and still learn if the level of
              engagement is high. A good gamification strategy with high levels
              of engagement will lead to an increase in recall and retention.
            </div>
            <div className="callout guideline">
              <h2>Prompting behavioural change.</h2>
              Drive strong behavioural change especially when combined with the
              principles of repeated retrieval and spaced repetition.
            </div>
          </div>
          <p>
            From our research, we were able to draw out a few ideas of what
            successful gamification would look and how this could be
            accomplished, including
            <ul>
              <li>
                Achievement systems, e.g. task-reward systems that reward the
                user with points, unlock bonus content, or award status symbols
              </li>

              <li>
                Measurable goals with a purpose - this may be to reinforce
                favourable behaviours such as using the Atlassian iPads to aid
                navigation
              </li>

              <li>
                Reward motivation - potentially more rewarding intrinsically
                than extrinsically
              </li>

              <li>Leaderboards to fuel competition</li>

              <li>Framed with a story to increase engagement</li>

              <li>Mechanics are simple, and don’t require thought to follow</li>
            </ul>
          </p>
          <h3>What is way-finding?</h3>
          <p>We classified types of way-finding into 3 distinct types</p>
          <div className="guidelines">
            <div className="callout guideline">
              <h2>Locating</h2>
              People operate in the locate mode of way-finding when they know
              precisely what they’re looking for, but need help discovering
              where to look.
            </div>
            <div className="callout guideline">
              <h2>Exploring</h2>
              When people explore, the journey is as important as getting to the
              destination. As people encounter new information, their
              information needs evolve, making exploring an iterative, on-going
              process.
            </div>
            <div className="callout guideline">
              <h2>Meandering</h2>
              People aren’t always looking for something in particular. They may
              have other motives such as having fun or killing time.
            </div>
          </div>
          <p>
            From our research, we were able to draw out a few ideas of what
            successful way-finding would look and how this could be
            accomplished, including
            <ul>
              <li>
                Structured districts Structured districts are the main
                categories into which we can divide an environment. Logical,
                clear districts are important to helping users understand an
                information environment at its most basic level. Districts
                should correspond to the user’s own mental model. (like using
                genre to categories movies)
              </li>
              <li>
                Positional cues. Like a 'You are here' marker on a map,
                positional cues help users to orient themselves within an
                information environment.
              </li>
              <li>
                Survey knowledge. While positional cues more often depict where
                users have been, survey knowledge helps users to ascertain where
                they should go next.
              </li>
              <li>
                Clear paths. In a physical environment, having a clear line of
                sight to your destination greatly simplifies the act of getting
                there. Similarly, in information environments, providing clear
                paths ensures that users always have a clear way forward.
              </li>
              <li>
                Coherent interactions. Layers, or filters, should not appear
                solely in search mode, as is far too often the case; and it is
                vital that we not divorce keyword searches from districts.
                Instead, allow users to navigate to districts, apply layers, and
                perform keyword searches iteratively, using these interactions
                in conjunction with one another.
              </li>
            </ul>
          </p>
          <h2 id="building-empathy">Building empathy</h2>
          <p>
            Once we had some more in depth understanding of gamification and
            way-finding, we needed to determine what our users would look like,
            as this would influence our solution. We didn't have a lot of time
            to flesh out complex personas, so we made do with a few
            representative users.
          </p>
          <figure className="space-down-l space-up-xs">
            <div className="row">
              <div className="column">
                <a
                  href={require("../assets/charliego/persona3.png")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Persona Max"
                    className="border"
                    src={require("../assets/charliego/persona3.png")}
                  />
                </a>
              </div>
              <div className="column">
                <a
                  href={require("../assets/charliego/persona4.png")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Persona Rochelle"
                    className="border"
                    src={require("../assets/charliego/persona4.png")}
                  />
                </a>
              </div>
            </div>
          </figure>
          <p>
            We determined that there were two user flows we needed to consider:
            that of the new hire, taking the tour, and that of the facilitator,
            setting up and hosting the tour., and a screenshot of the final
            prototype detail. I drew some rough journey maps to get an idea of
            how the journey currently looks.
          </p>
          <figure className="space-down-l space-up-xs">
            <div className="row">
              <div className="column">
                <a
                  href={require("../assets/charliego/journeymap1.jpg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Journey map 1"
                    className="border"
                    src={require("../assets/charliego/journeymap1.jpg")}
                  />
                </a>
              </div>
              <div className="column">
                <a
                  href={require("../assets/charliego/journeymap2.jpg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Journey map 2"
                    className="border"
                    src={require("../assets/charliego/journeymap2.jpg")}
                  />
                </a>
              </div>
            </div>
          </figure>
          <h2>Architecture</h2>
          <p>
            Since it was a hackathon, we needed to really nut out the
            architecture while we worked on the designs, so that our engineering
            team could start building something. We initally gave them a very
            simple architecture diagram as seen here.
          </p>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Architecture"
              src={require("../assets/charliego/architecture.png")}
            />
          </figure>
          <h2 id="ideation">Ideation</h2>
          <p>
            Our initial designs were very simple, but helped us to flesh out how
            the areas interacted, and how a user would accomplish a given task
            in the app. I was working with one other designer, and we found
            discussion rather than drawing was easier with the time crunch, and
            the only sketch ideation we have was very loose.
          </p>
          <figure className="space-down-l space-up-xs">
            <div className="row">
              <div className="column">
                <a
                  href={require("../assets/charliego/charliego-ideation1.jpeg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Ideation 1"
                    className="border"
                    src={require("../assets/charliego/charliego-ideation1.jpeg")}
                  />
                </a>
              </div>
              <div className="column">
                <a
                  href={require("../assets/charliego/charliego-ideation2.jpeg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Ideation 2"
                    className="border"
                    src={require("../assets/charliego/charliego-ideation2.jpeg")}
                  />
                </a>
              </div>
              <div className="column">
                <a
                  href={require("../assets/charliego/charliego-ideation3.jpeg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Ideation 3"
                    className="border"
                    src={require("../assets/charliego/charliego-ideation3.jpeg")}
                  />
                </a>
              </div>
              <div className="column">
                <a
                  href={require("../assets/charliego/charliego-ideation4.jpeg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Ideation 4"
                    className="border"
                    src={require("../assets/charliego/charliego-ideation4.jpeg")}
                  />
                </a>
              </div>
            </div>
          </figure>
          <h2 id="prototyping">Prototyping</h2>
          <p>
            Since we were working under hackathon conditions, we had very little
            time working on the design before our engineers wanted to start work
            on the actual app. We compromised by providing loose wireframes, so
            the team could start building the framework, and then we continued
            to work on the styling and more specific design screen by screen so
            that we would eventually have a complete prototype and also a
            working app not too far down the line. This is small gif of the
            process.
          </p>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Final gif"
              src={require("../assets/charliego/charliego-gif.gif")}
            />
          </figure>
          <p>
            Obviously the final prototype was significantly more detailed, and
            included designs for the facilitator experience too.
          </p>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Figma mockup"
              src={require("../assets/charliego/figmamockup.png")}
            />
          </figure>
          <h2 id="solution">The Solution</h2>
          <h3>The Employee Experience</h3>
          <p>
            The first experience we worked on was for the employees. Ensuring
            buy in wasn't as important here, as we knew that new hires would
            follow the guidelines given by the facilitator.
          </p>
          <div className="prototype">
            <img
              alt="Onboarding gif"
              className="protoImage"
              src={require("../assets/charliego/onboarding.gif")}
            />
            <div className="protoText">
              <h4>The onboarding flow</h4>
              Each game has a unique code which lets players join. This is the
              first information the app receives in order to set your game up
              correctly
              <br />
              <br />
              Each team that signs up gives a name so that the scores can be
              compared against current and future teams for one true winner!
            </div>
          </div>
          <div className="prototype">
            <img
              alt="Scavenger hunt gif"
              className="protoImage"
              src={require("../assets/charliego/scavengerhunt.gif")}
            />
            <div className="protoText">
              <h4>Example game: Scavenger Hunt</h4>
              This game allowed you to unlock the floor-plan of the area by
              scanning QR codes like a puzzle. Like all of the games, we gave
              directions to begin with in order to orient the team correctly,
              before sending them off with a map to find the QR codes to scan.
              <br />
              <br />
              This gif also shows the home screen. The achievements are
              currently blacked out as the user has not played any games or won
              any points yet. The map gives you immediate access to where you
              are and your surroundings, to help prevent getting lost. The top
              right corner holds the total points of the current team playing.
            </div>
          </div>
          <div className="prototype">
            <img
              alt="Leaderboard gif"
              className="protoImage"
              src={require("../assets/charliego/leaderboard.gif")}
            />
            <div className="protoText">
              <h4>The leaderboard</h4>
              Each game awarded the team with points, which finally allowed them
              to find their correct place on the leaderboard. Each of the teams
              also took photos throughout the challenges (at the end of each
              game, to collect points you had to take a group photo) which was
              presented to you as a collage at the end of the game as a
              keepsake.
            </div>
          </div>
          <h3>The Facilitator Experience</h3>
          <p>
            The second experience we worked on was for the facilitator. We knew
            it was important to gain buy in from recruitment on this, as they
            were the ones facilitating the onboarding. We did this through a
            comprehensive, yet straightforward interface that allowed maximum
            control.
          </p>
          <figure className="space-down-l space-up-xs">
            <img
              alt="Facilitator experience gif"
              src={require("../assets/charliego/facilitator.gif")}
            />
          </figure>
          <p>
            We aimed to cover all the major pain points we explored including:
            <ul>
              <li>
                Allowing the facilitator to run separate but simultaneous
                onboardings for both Sydney office buildings, allowing new hires
                to explore the area they will be working in.
              </li>
              <li>
                Tools to help 'round up' the teams if they were taking too long,
                including sending messages, or ending the game prematurely.
              </li>
              <li>
                Provides safety functionality by ensuring teams are in the
                correct areas, and allowing the facilitator to know location of
                team members in case of emergency.
              </li>
            </ul>
          </p>
          <h2 id="retrospect">In Retrospect</h2>
          <p>
            This was a very intense, exciting, fast paced project. It was the
            first hackathon I have done, and though I think in hindsight we
            over-committed, we managed to achieve everything we set out out to
            have in a solution. We provided an engaging challenge to new hires
            that we believe, based on research, will improve recall and
            retention of new information. We also provided a low stress
            facilitator experience that can be set up prior, requires no
            volunteers, and allows customisation.
          </p>
          <p>
            There were definitely several compromises - we didn't get to do much
            research, and we got to do no user studies, instead settling for
            representative personas from what we had observed. Luckily we were
            all the target audience, but had we not been, we may have made
            assumptions that guided us in the wrong direction.
          </p>
          <p>
            I surprisingly really enjoyed working under this time pressure, and
            found it gave me a great opportunity to be creative.
          </p>
        </section>
      </div>
    </>
  );
};

export default Onboarding;
