import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <header>
        <div className="block">
          <a href="/" draggable="false" title="Home" className="home">
            Jasmine Worsley
          </a>
        </div>
      </header>

      <Outlet />

      <div className="footer">
        <h2 id="footer-description">Jasmine Worsley</h2>
        <p>
          <a href="https://www.linkedin.com/in/jasmine-worsley" id="social">
            <img src={require("../assets/linkedin-icon.png")} id="icon" />
            Linkedin
          </a>
          <a href="https://www.behance.net/jasmineworsley" id="social">
            <img src={require("../assets/behance-icon.png")} id="icon" />
            Behance
          </a>{" "}
        </p>
      </div>
    </>
  );
};

export default Layout;
