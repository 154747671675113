import { Projects } from "../projects";

export default function ProjectHome() {
  return (
    <div className="App">
      <html id="global" lang="en">
        <Projects />
      </html>
    </div>
  );
}
