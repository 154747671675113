import { Helmet } from "react-helmet";

const NoPage = () => {
  return (
    <div className="text">
      <Helmet>
        <title>Jasmine Worsley | Not Found</title>
      </Helmet>
      <section>
        <h1>
          <span>404</span>
        </h1>
        <p>Whoops, doesn't seem like that page exists!</p>
      </section>
    </div>
  );
};

export default NoPage;
