import { Helmet } from "react-helmet";

const Flatform = () => {
  return (
    <>
      <Helmet>
        <title>Jasmine Worsley | Flatform</title>
      </Helmet>
      <div className="text">
        <div className="topCorner">
          <a href="#initial_research">Research</a>
          <a href="#the_problem">Problem</a>
          <a href="#ideating">Ideation</a>
          <a href="#the_solution">Solution</a>
          <a href="#delivering">Prototype</a>
          <a href="#retrospect">Retrospect</a>
        </div>
        <section>
          <h1>
            <span>Flatform app</span>
          </h1>
          <div className="callout">
            <div className="content">
              <h2>Overview—</h2>
              <p>
                Flatform began as a collaborative research project about pain
                points in flat living. Following this research, it became an app
                that aimed to quell some of these daily struggles.
              </p>
            </div>
            <div className="content-short">
              <h2>Role</h2>
              <p>Co-founder, Lead Designer</p>
            </div>
            <div className="content-short">
              <h2>Time commitment</h2>
              <p>3 months</p>
            </div>
          </div>
          <p className="note">*A flat is NZ slang for a share house.</p>
          <h2 id="initial_research">Initial research</h2>
          <p>
            Flatform didn't initially start with the intention of being an app.
            We began the project by seeking to understand thoroughly before
            determining even the vehicle for a solution. We explored the
            difficulties that students and young professionals were facing when
            living with friends and strangers through a myraid of different UX
            research strategies, beginning with in depth in person interviews.{" "}
          </p>
          <h3>The interviews</h3>
          <p>
            We conducted interviews first, starting with a representative sample
            of 10 respondents. We then conducted 5 further interviews, reaching
            a point of saturation. We chose to interview over other methods of
            research as we felt these would elicit the most information and
            avoid suggestions of predetermined answers. This also allowed
            greater flexibility during questioning, and facilitated follow-up
            questions in areas of interest to get the best possible insight into
            the tasks users accomplish during their flatting experiences. We
            ensured that we were reaching a broad user group by not asking
            multiple people in the same flat, contacting people in other
            countries, and talking to people of differing age groups and living
            situations for a rounded sample pool.
          </p>
          <p>
            Our interviews followed a set of structured questions to create
            focus and consistency, and avoid post-hoc rationalisation. We did
            probe interesting leads where we came across them, which made our
            data set a lot more comprehensive. This ensured we were able to
            capture relevant quantitative and qualitative data.{" "}
          </p>

          <figure className="space-down-l space-up-xs">
            <img alt="Users" src={require("../assets/flatform-users.png")} />
          </figure>

          <h2 id="building_empathy">Building empathy</h2>
          <p>
            With the qualitative data we were able to gather, we created 6
            personas (three of these are attached below). When negotiating with
            our Product Owner, we ended up deciding not to support one of these
            personas - Families. The families category didn’t fit well into our
            categorisation of a “flat”, and pushed the scope more than we had
            capacity for.
          </p>
          <figure className="space-down-l space-up-xs">
            <div className="row">
              <div className="column">
                <a
                  href={require("../assets/persona1.jpg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Persona Ali"
                    className="border"
                    src={require("../assets/persona1.jpg")}
                  />
                </a>
              </div>
              <div className="column">
                <a
                  href={require("../assets/persona2.jpg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Persona John"
                    className="border"
                    src={require("../assets/persona2.jpg")}
                  />
                </a>
              </div>
              <div className="column">
                <a
                  href={require("../assets/persona3.jpg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Persona Sam"
                    className="border"
                    src={require("../assets/persona3.jpg")}
                  />
                </a>
              </div>
            </div>
          </figure>

          <h2 id="the_problem">Converging on the problem</h2>
          <p>
            With the personas we built, we were able to establish who our
            solution should support, and the methods in which a solution could
            best be built. We found from our research that among our target
            group of students and young professionals, the main communication
            device they had best access to was a phone. We decided that our
            users could be best supported through an app in this case, with
            potential for extensibility in future.
          </p>
          <p>
            The personas all expressed similar frustrations that our team felt
            we could successfully mitigate. With the vessel of an app in mind, I
            wrote and categorised tasks to determine our users key issues. These
            were discussed with our original research participants and adjusted
            to best represent our personas and the qualitative data we had.
          </p>
          <figure className="space-down-l space-up-xs">
            <div className="row">
              <div className="column">
                <a
                  href={require("../assets/tasks2.jpg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Tasks 1"
                    className="border"
                    src={require("../assets/tasks2.jpg")}
                  />
                </a>
              </div>
              <div className="column">
                <a
                  href={require("../assets/tasks1.jpg")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="Tasks 2"
                    className="border"
                    src={require("../assets/tasks1.jpg")}
                  />
                </a>
              </div>
            </div>
          </figure>
          <h2 id="ideating">Ideating</h2>
          <p>
            Now that we had a comprehensive description of our users problems,
            we were able to begin to explore a solution. I started with a
            wireframe of the system and how it would all fit together.
          </p>
          <h3>Creating design guidelines</h3>
          <p>
            I wrote three key design principles I wanted us to follow when
            ideating, primarily to keep a focus particularly when discussing
            with stakeholders and the team.
          </p>
          <div className="guidelines">
            <div className="callout guideline">
              <h2>Consistency</h2>
              Task completion should be structured the same everywhere, so users
              can learn the system.
            </div>
            <div className="callout guideline">
              <h2>Familiarity</h2>
              Users should know what to expect based on their previous
              interactions with the app and with other popular interfaces.
            </div>
            <div className="callout guideline">
              <h2>Simplicity</h2>
              Reducing visual clutter where possible so not to distract the user
              from important but mundane tasks.
            </div>
          </div>
          <h3>Application Architecture</h3>
          <p>
            As we had a comprehensive list of tasks now, I started to visualise
            potential solutions by architecting the structure the application
            would follow.
          </p>
          <figure className="space-down-l space-up-xs">
            <img alt="Architecture" src={require("../assets/flow.jpg")} />
          </figure>
          <h3>Ideation workshop</h3>
          <p>
            This process started with an ideation workshop that I ran with my
            team members. As a startup style project, all of the team members
            were very passionate about the subject matter and very opinionated
            about its potential solutions. It was a really interesting workshop
            to run as it gathered design ideation from not only designers, but
            also product owners, and several engineers. This truly helped to
            explore a broad number of potential options, and spar to come to
            decisions we felt were truly right for the users.
          </p>
          <p>
            From the tasks we designated as important, we each drew one or more
            ideas without discussing with the group. We then followed this up by
            taking turns explaining our solution and discussing pros and cons
            with the rest of the team. Below is a small selection of these
            designs and a quick summary of points we discussed with each.
          </p>
          <figure className="space-down-l space-up-xs">
            <a
              href={require("../assets/ideationworkshop.png")}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Ideation workshop"
                src={require("../assets/ideationworkshop.png")}
              />
            </a>
          </figure>

          <h2 id="the_solution">Converging on solution </h2>
          <p>
            With the ideation that the team had done, I worked on a low fidelity
            mockup that collated some of these discussed ideas.
          </p>
          <h4>Dashboard</h4>
          <p>
            The dashboard design came out of a convergence of ideas from the
            workshop, which itself was inspired by platforms like Samsung Health
            and Windows 10. The tiles are reorderable based on user preference,
            and different tiles can be chosen to show previews of different
            information.
          </p>
          <div className="callout">
            <h2>Navigation research & testing</h2>I conducted some research on
            this page before further iterations to work out the navigation
            format. The two distinct options were
            <ul>
              <li>
                A menu and a tile based home page. Provides multiple forms of
                navigation, but users may be more confused where to look.{" "}
              </li>
              <li>
                A combined menu/tile based home page. One streamlined
                navigation, but may be overwhelming.
              </li>
            </ul>
            When asking a user to determine where they could find a particular
            item, I found that the mean time for a novice (first time looking at
            this interface) was faster (2182.14ms) with a menu and tile based
            home page than with one more specific menu (4516.79ms). They were
            especially faster with the 5 frequent items. This was potentially
            because the most common items were easier to access on the tile
            page.
            <br />
            <br />
            Expert users were faster across the board, with a smaller difference
            (less than 1000ms, as opposed to less than 2000ms) between frequent
            and infrequent items than novice users in general. However, with the
            assumption that our users will be more novice as they won't look at
            the app too often, we decided that the combination of menu and tile
            designs was optimal.
            <figure className="calloutImage">
              <img
                alt="Nav research graph"
                src={require("../assets/flatform/navresearch.png")}
              />
            </figure>
          </div>
          <figure className="space-down-l space-up-xs">
            <a
              href={require("../assets/dashboard-prelim.png")}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Dashboard ideation"
                src={require("../assets/dashboard-prelim.png")}
              />
            </a>
          </figure>
          <h4>The Document Library</h4>
          <p>
            We decided that it was important to include a folder based library
            structure, for categorization purposes and also for familiarity.
            Since we have folder functionality, I added breadcrumbs which show
            the current directory location, so the user can quickly understand
            where they are, helping to promote learnability. We added the
            ability to pin important documents to the top, for easy access. The
            ability to search also was important, as was consistent with other
            file systems. It also allows the user to quickly find a document
            without scrolling.
          </p>
          <figure className="space-down-l space-up-xs">
            <a
              href={require("../assets/documents-prelim.png")}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Documents ideation"
                src={require("../assets/documents-prelim.png")}
              />
            </a>
          </figure>

          <h4>Regular bill payment</h4>
          <p>
            We converged on a list-style interface for displaying a history of
            transactions. Partially this was due to its similarity to most
            banking apps, like Kiwibank and BNZ. This promotes learnability
            through familiarity.{" "}
          </p>
          <p>
            Some things that came out of the ideation workshop included
            aggregation of items by week and title, and an indication of how
            many people have paid towards something when cost-splitting. This
            would be useful for quick glances at the list.
          </p>
          <figure className="space-down-l space-up-xs">
            <a
              href={require("../assets/bills-prelim.png")}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Bills ideation"
                src={require("../assets/bills-prelim.png")}
              />
            </a>
          </figure>

          <h4>Routine Roster</h4>
          <p>
            {" "}
            The key design considerations on this page included reducing clutter
            and the use of natural language. This lead to two key decisions: the
            ability to hide and show filters, and the headers like “Due today”,
            “Due tomorrow”, and “Due next week” to promote quicker processing.
          </p>
          <figure className="space-down-l space-up-xs">
            <a
              href={require("../assets/routine-prelim.png")}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Routine ideation"
                src={require("../assets/routine-prelim.png")}
              />
            </a>
          </figure>

          <h4>Shopping List</h4>
          <p>
            {" "}
            Some key design notes from this screen include the ability to tag
            items (which causes them to surface in relevant tiles in the
            dashboard), expansion of list items for editing (reduces clutter),
            and gestures. A left swipe gesture allows deletion, while right
            allows completion. In our evaluation of our tasks, we found a common
            use case was buying multiple of a single item - in our case,
            purchasing 15 avocados. If the number of items is greater than 1,
            then it’s clearly shown in the list view for easy use when shopping.
          </p>
          <figure className="space-down-l space-up-xs">
            <a
              href={require("../assets/list-prelim.png")}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="List ideation"
                src={require("../assets/list-prelim.png")}
              />
            </a>
          </figure>

          <h4>Payment Splitting</h4>
          <p>
            If you insert all of the specific dollar amounts for each person and
            these do not add up, then the final number will display red and the
            send button will be greyed out to ensure no accidental overpayment.
            This visual cue is helpful in preventing unwanted actions
          </p>
          <figure className="space-down-l space-up-xs">
            <a
              href={require("../assets/split-prelim.png")}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Pay split ideation"
                src={require("../assets/split-prelim.png")}
              />
            </a>
          </figure>

          <h2 id="user_testing">User testing</h2>
          <p>
            The application went through two sessions of intense user testing
            before any development started. This was almost entirely
            observational - we set some tasks and observed and recorded how
            users approached the problem. We noticed some slips, and altered our
            flows to prevent them further. Some of these included changing
            gestures to have more explicit actions (e.g. swipe on shopping list
            became a physical checkbox), simplifying the chores roster to not
            have so many confusing options, and changing the highlight style of
            flatmate icons to be clearer.
          </p>

          <h2 id="delivering">Delivering the solution</h2>
          <p>
            After converging on a solution and testing with users, I reviewed
            this with our product owner, and then later with engineers to
            discuss feasibility. I looked over our design principles to ensure
            we were on the right track, and then set out to create a testable
            prototype. Below are a few examples of important parts of the app.
          </p>
          <div className="prototype">
            <img
              alt="Dashboard prototype"
              className="protoImage"
              src={require("../assets/flatform/dashonboard.gif")}
            />
            <div className="protoText">
              <h4>The onboarding flow & dashboard</h4>
              The onboarding flow was simplified to only require the most basic
              information, allowing a user to get straight into it and try out
              the product.
              <br />
              <br />
              For the dashboard, the most used and important features are
              positioned at the top of the dashboard, and depending on screen
              size, some items will require scrolling. In most cases this limits
              additional swipe time, and lets users become quicker at checking
              and using the app over time.
              <br />
              <br />
              We left the cooking roster at the bottom, as only around half of
              the flat we surveyed used a cooking roster , however it was
              included as we felt it would be a dealbreaker for flats that did
              rotate cooking. For flats that require this, it can be moved in
              our dashboard edit screen to wherever the user thinks it should
              go. We felt this was the best compromise between adding clutter
              and affording functionality.
              <div className="callout inline">
                We associated each user in the flat with a customisable color,
                in order to promote a faster speed of processing.
              </div>
            </div>
          </div>
          <div className="prototype">
            <img
              alt="List prototype"
              className="protoImage"
              src={require("../assets/flatform/lists.gif")}
            />
            <div className="protoText">
              <h4>The shopping list & chore roster</h4>
              The shopping list and chores pages allow flatmates to collaborate
              according to their needs. Shopping items are bucketed into their
              related tags, as when shopping, as similar products often appear
              in the same aisle. This approach allows users to reduce both
              visual search time within the app, and the amount of time spent
              doing. The chore roster allows for some sorting, initially
              displaying chores by natural language dates for recognition
              purposes.
              <br />
              <br />
              By swiping up from either of these pages, an extra section is
              shown which allows a user to add new items to the respective list.
              All fields are clearly displayed encouraging the user detail,
              however still allowing for a simple items quickly. If the user
              slides the page away the item is saved in their cache to address
              later.
              <div className="callout inline">
                Both list forms in this system (Chores and Shopping) are almost
                identical, with minor changes. This was to afford familiarity
                and consistency and to align with our design guidelines.
              </div>
            </div>
          </div>
          <div className="prototype">
            <img
              alt="Expenses prototype"
              className="protoImage"
              src={require("../assets/flatform/expenses.gif")}
            />
            <div className="protoText">
              <h4>Expenses and payment splitting</h4>
              Users are able to quickly view all of the bank payments
              information including previous bills and current bills statuses.
              In NZ, shared accounts are quite difficult, and from our research
              we found often only one member of the flat would have access. This
              would have some legal and ethical considerations that would need
              to be further explored with NZ banks.
              <br />
              <br />
              The split payment option allows for users to quickly choose who in
              the flat is going to be involved in the payment splitting by
              simply tapping on the user icons to select the users. A user would
              input the numerical value to split in a fixed fashion, otherwise
              they can automatically just split evenly. Some further
              considerations for extensibility include fixed splits with
              different ratios.
            </div>
          </div>
          <div className="prototype">
            <img
              alt="Docs prototype"
              className="protoImage"
              src={require("../assets/flatform/docs.gif")}
            />
            <div className="protoText">
              <h4>Cooking roster & Document library</h4>
              The cooking roster is very straightforward, allowing the flat to
              simply select days for who is cooking (or multiple people) on a
              given night.
              <br />
              <br />
              The document library is a little more complex, mimicking the
              design of iCloud and Google Drive to afford familiarity. We added
              the ability to pin specific items from the directory structure to
              the Documents home screen. This allows users to pin frequently
              used and important documents for quick access. To avoid cluttering
              up the pinned tiles, it was decided that a long press should be
              used to unpin tiles directly from the Documents home screen.
            </div>
          </div>

          <h2 id="retrospect">In Retrospect</h2>
          <p>
            This project truly let me own the design and experience of the
            solution, and learn about creating team buy in. Working in a larger
            group than I was used to allowed me to improve my skills in liasing
            with engineering and PM, particularly in making compromises. I
            really enjoyed working with users and observing their behaviour.
            This was one of the first times I really got to put my UX research
            skills into practice, and I was able to truly pinpoint users slips,
            rather than just assuming them.
            <br />
            <br />
            There are many things I would like to have added to these designs,
            but with time and resource constraints I think we as a team
            successfully prioritised customer value and delivered a solution
            that checks all of our users boxes.
          </p>
        </section>
      </div>
    </>
  );
};

export default Flatform;
